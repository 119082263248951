import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { css } from "goober";

import { readState } from "@/__main__/app-state.mjs";
import { appURLs } from "@/app/app-urls.mjs";
import type { Rarity } from "@/game-fortnite/components/ItemBox";
import { ItemBox, ItemContainer } from "@/game-fortnite/components/ItemBox";
import { getWeaponsPageParamsStr } from "@/game-fortnite/utils/get-params.mjs";
import getWeaponKey from "@/game-fortnite/utils/get-weapon-key.mjs";
import useWeaponsFromItems from "@/game-fortnite/utils/use-weapons-from-items.mjs";
import GameGuideTable from "@/marketing/GameGuideTable.jsx";
import GameGuideTileLg from "@/marketing/GameGuideTileLg.jsx";
import { getLocale } from "@/util/i18n-helper.mjs";
import { useRoute } from "@/util/router-hooks.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

const COLS = [
  {
    i18n: ["common:weapon", "Weapon"],
    dataKey: "weapon",
  },
  {
    i18n: ["fortnite:stats.elimsPerMatch", "Elims / Match"],
    dataKey: "elims",
  },
  {
    i18n: ["common:accuracy", "Accuracy"],
    dataKey: "accuracy",
  },
  {
    i18n: ["fortnite:stats.damagePerMatch", "DMG / Match"],
    dataKey: "damage",
  },
];

export default function HomeStatisitcsTile() {
  const {
    fortnite: {
      stats: { weaponsDerived },
      seasons,
    },
  } = useSnapshot(readState);
  const { t } = useTranslation();
  const { searchParams } = useRoute();
  const paramString = getWeaponsPageParamsStr(searchParams, {
    season: seasons[0]?.id,
  });
  const stats = weaponsDerived[paramString];

  const weapons = useWeaponsFromItems();

  const data = useMemo(() => {
    if (!stats) return null;
    return Object.entries(stats)
      .map(([weaponId, weaponStats]) => {
        const weapon = weapons[weaponId.toLowerCase()];
        if (!weapon || !weaponStats) return null;
        return {
          id: weapon.id,
          name: weapon.displayName,
          rarity: weapon.rarity,
          imageKey: weapon.icon,
          elims: weaponStats.elims,
          accuracy: weaponStats.accuracy,
          damage: weaponStats.damage,
          link: `/fortnite/database/weapon/${getWeaponKey(weapon.displayName)}`,
        };
      })
      .filter((w) => w)
      .sort((a, b) => b?.elims - a?.elims);
  }, [stats, weapons]);

  return (
    <GameGuideTileLg
      title={["common:navigation.statistics", "Statistics"]}
      description={[
        "home:guides.fortnite.statistics.description",
        "Discover which Weapons perform best with eliminations, accuracy, damage and other helpful statistics.",
      ]}
      buttonText={["common:navigation.viewStatistics", "View Statistics"]}
      href={"/fortnite/stats"}
    >
      <GameGuideTable className={Table()}>
        <GameGuideTable.Header>
          <div className="weapon-meta">{t(COLS[0].i18n)}</div>
          <div className="stat">{t(COLS[1].i18n)}</div>
          <div className="stat">{t(COLS[2].i18n)}</div>
          <div className="stat">{t(COLS[3].i18n)}</div>
        </GameGuideTable.Header>
        <GameGuideTable.Body numRows={5}>
          {(data || []).map((row, i) => (
            <GameGuideTable.Row key={i} link={row.link}>
              <div className={`weapon-meta type-subtitle--bold`}>
                <ItemContainer>
                  <ItemBox
                    id={row.id}
                    name={row.name}
                    rarity={row.rarity.toUpperCase() as Rarity}
                    src={`${appURLs.CDN}/fortnite/images/${row.imageKey}.webp`}
                  />
                  <p className="type-callout--bold shade0 name">{row.name}</p>
                </ItemContainer>
              </div>
              <div className="stat type-body2-form--bold">
                {row.elims.toLocaleString(getLocale(), {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </div>
              <span className="stat type-body2-form--bold">
                {(row.accuracy / 100).toLocaleString(getLocale(), {
                  minimumFractionDigits: 1,
                  maximumFractionDigits: 1,
                  style: "percent",
                })}
              </span>
              <span className="stat type-body2-form--bold">
                {row.damage.toLocaleString(getLocale(), {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                })}
              </span>
            </GameGuideTable.Row>
          ))}
        </GameGuideTable.Body>
      </GameGuideTable>
    </GameGuideTileLg>
  );
}

const Table = () => css`
  .weapon-meta {
    flex: 1;
    display: flex;
    align-items: center;
    gap: var(--sp-3);
    color: var(--shade0);
  }
  .weapon-img {
    width: 4.5rem;
    height: auto;
    aspect-ratio: 350 / 197;
    border-radius: var(--br-sm);
  }
  .weapon-name {
    max-width: 11ch;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .stat {
    display: flex;
    justify-content: center;
    width: 17%;
    white-space: nowrap;
  }
  .tier svg {
    width: var(--sp-6);
    height: var(--sp-6);
  }
`;
